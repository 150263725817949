<template>
  <div>
    <section class="hero" :class="[isGhost ? 'is-dark' : 'is-primary']">
      <header class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" :href="url">
              <img :src="`${this.url}/assets/logo2.png`" alt="Logo">
            </a>
            <span class="navbar-burger" :class="[isActiveNavbarMenu ? 'is-active' : '']"
              @click="activeNavbarMenu">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div class="navbar-menu" :class="[isActiveNavbarMenu ? 'is-active' : '']"
            @click="activeNavbarMenu">
            <div class="navbar-end">
              <span class="navbar-item" v-if="roomId">
                <VDropdown
                  :triggers="['hover', 'focus', 'touch']"
                  :autoHide="true"
                >
                  <template #popper>
                    <span class="is-size-7 has-text-link">共有用URLをクリップボードにコピーします。</span><br />
                    <button class="button is-primary" @click="copyToClipboard">{{this.url}}/{{this.roomId}}</button>
                  </template>
                  <a class="button is-primary is-inverted" @click="copyToClipboard">
                    <span class="icon"><i class="fas fa-copy"></i></span>
                    <span>Copy share link</span>
                  </a>
                </VDropdown>
              </span>
              <span class="navbar-item">
                <a class="button is-primary" href="https://forms.gle/4xckQvhxWuURB3um7" target="_blank" rel="noopener noreferrer">
                  <span class="icon"><i class="fas fa-comment"></i></span>
                  <span>ご意見・お問合せ</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </header>
      <div class="hero-body">
        <p class="title">
          Team poker
        </p>
        <p class="subtitle">
          WEBブラウザで簡単にプランニングポーカーなどの投開票をスムーズに実施できます。<br />
          <span class="subtitle is-6">Zoom/Meet/Teamsなど参加者全員とビデオ会議 or 音声通話している状態で利用することを前提としています。<br />ルームを作成してリンクを共有するだけで利用可能です。なお、作成したルームは最後の利用から1時間後に無効化されます。</span>
        </p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="tile is-parent">
          <div class="tile">
            <template v-if="!roomId">
              <VDropdown
                class="tile is-3"
                :triggers="[]"
                :shown="!joinRoomId"
                :autoHide="false"
              >
                <template #popper>
                  <span class="is-size-7 has-text-link">
                    こちらからルームを作成して、URLを参加者に共有しましょう。
                  </span>
                </template>
                <button class="button is-info is-light is-medium is-fullwidth" @click="setRoomId">新しいルームを作成</button>
              </VDropdown>
              <div class="tile is-4">
                <input class="input is-link is-light is-medium" type="text" placeholder="room id" v-model="joinRoomId">
              </div>
              <div class="tile is-4 buttons are-medium">
                <VDropdown
                  :triggers="['hover', 'focus']"
                  :offset="[0, 10]"
                >
                  <template #popper>
                    <span class="is-size-7 has-text-link">投票に参加する場合は、こちらから入場して名前を設定してください。</span>
                  </template>
                  <button class="button is-link is-light" @click="joinRoom(false)">入場</button>
                </VDropdown>
                <VDropdown
                  :triggers="['hover', 'focus']"
                  :offset="[0, 10]"
                >
                  <template #popper>
                    <span class="is-size-7 has-text-link">投票に参加せずに、投票状況を観覧する場合は、こちらから入場してください。</span>
                  </template>
                  <button class="button is-ghost" @click="joinRoom(true)">観覧入場</button>
                </VDropdown>
              </div>
            </template>
            <template v-else>
              <VDropdown
                class="tile is-4"
                :triggers="['hover', 'focus', 'touch']"
                :autoHide="true"
              >
                <template #popper>
                  <span class="is-size-7 has-text-link">共有用URLをクリップボードにコピーします。</span><br />
                  <button class="button is-primary" @click="copyToClipboard">{{this.url}}/{{this.roomId}}</button>
                </template>
                <button class="button is-info is-light is-medium" @click="copyToClipboard">
                  <span class="icon"><i class="fas fa-copy"></i></span>
                  <span>{{ roomId }}</span>
                </button>
              </VDropdown>
              <template v-if="!isGhost">
                <VDropdown
                  class="tile is-4"
                  :triggers="[]"
                  :shown="!name"
                  :autoHide="false"
                  :offset="[0, 10]"
                >
                  <template #popper>
                    <span class="is-size-7 has-text-link">
                      名前を設定して投票に参加しましょう。
                    </span>
                  </template>
                  <form @submit.prevent="setName" class="tile is-12">
                    <input class="input is-medium" type="text" autocomplete="nickname" v-model="name">
                  </form>
                </VDropdown>
                <div class="tile is-1">
                  <button class="button is-info is-light is-medium" @click="setName" :disabled="!name">
                    <span class="icon"><i class="fas fa-sign-in-alt"></i></span>
                  </button>
                </div>
                <VDropdown
                  class="tile is-2"
                  :triggers="['hover', 'focus']"
                  :offset="[0, 10]"
                >
                  <template #popper>
                    <span class="is-size-7 has-text-link">ownerに設定すると投票中の選択状況がわからなくなります。画面共有している場合にご利用ください。</span>
                  </template>
                  <div class="buttons has-addons">
                    <button class="button" :class="[isOwner? '' : 'is-info is-selected']" @click="setOwner(false)">member</button>
                    <button class="button" :class="[isOwner? 'is-info is-selected' : '']" @click="setOwner(true)">owner</button>
                  </div>
                </VDropdown>
              </template>
            </template>
          </div>
        </div>
        <template v-if="roomData">
          <div class="tile is-parent">
            <VDropdown
              v-if="roomData.selectItems.length"
              class="tile is-12"
              :triggers="['hover', 'focus']"
              :offset="[0, 10]"
            >
              <template #popper>
                <span class="is-size-7 has-text-link">選択項目を変更できます。自由に項目を追加、削除することもできます。</span>
              </template>
              <button class="button is-danger is-light is-fullwidth is-small" @click="openShowSettingModal" >
                <span class="icon is-small"><i class="fas fa-edit"></i></span>
                <span>選択項目変更</span>
              </button>
            </VDropdown>
            <VDropdown
              v-else
              class="tile is-12"
              :triggers="[]"
              :shown="!isShowSettingModal"
              :autoHide="false"
              :offset="[0, 10]"
            >
              <template #popper>
                <span class="is-size-7 has-text-link">
                  選択項目を設定してください。<br />
                  こちらの設定は、参加者全員が実施できます。途中で変更する事も可能です。
                </span>
              </template>
              <button class="button is-danger is-light is-fullwidth is-large" @click="openShowSettingModal" >
                <span class="icon is-small"><i class="fas fa-edit"></i></span>
                <span>選択項目設定</span>
              </button>
            </VDropdown>
          </div>
          <div class="modal" :class="[isShowSettingModal ? 'is-active' : '']">
            <div class="modal-background"></div>
            <div class="modal-content">
              <div class="box">
                <div class="buttons are-medium is-3">
                  <button @click="mode1220" class="tile button is-warning is-dark">
                    0.5, 1〜20
                  </button>
                  <button @click="modeFibonacci" class="tile button is-warning is-dark">
                    フィボナッチ
                  </button>
                  <button @click="modeSML" class="tile button is-warning is-dark">
                    Tシャツ
                  </button>
                  <button @click="modeTension" class="tile button is-warning is-dark">
                    気分
                  </button>
                  <button @click="modeOkrSelfDegree" class="tile button is-warning is-dark">
                    OKR自信度
                  </button>
                  <button @click="modeHighMediumLow" class="tile button is-warning is-dark">
                    優先度(高、中、低)
                  </button>
                  <button @click="modePlayingCards" class="tile button is-warning is-dark">
                    トランプ
                  </button>
                  <button @click="modeJanlen" class="tile button is-warning is-dark">
                    じゃんけん✊✌️🖐️
                  </button>
                </div>
                <div class="columns is-flex-direction-row is-flex-wrap-wrap">
                  <div
                    class="column is-3"
                    v-for="(customItem, index) in customItems"
                    :key="index"
                  >
                    <input class="input is-rounded" type="text" v-model="customItems[index]" />
                  </div>
                  <div class="column">
                    <button class="button is-ghost" @click="customAdd" >
                      <span class="icon is-small"><i class="fas fa-plus-square"></i></span>
                    </button>
                    <button class="button is-ghost" @click="customTake" >
                      <span class="icon is-small"><i class="fas fa-minus-square"></i></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tile is-vertical">
                <button class="button is-primary is-light" @click="setSelectItems" >
                  <span class="icon is-small"><i class="fas fa-angle-double-down"></i></span>
                </button>
              </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="closeShowSettingModal"></button>
          </div>
          <div class="tile is-parent">
            <template v-if="isTitleEdit">
              <form @submit.prevent="(e) => setTitle(e.target.title.value)" class="tile is-parent">
                <div class="tile is-10">
                  <input class="input is-medium" type="text" name="title" :value="roomData.title">
                </div>
                <div class="tile is-2">
                  <button class="button is-info is-light is-medium" type="submit">
                    <span class="icon"><i class="fas fa-cat"></i></span>
                  </button>
                </div>
              </form>
            </template>
            <template v-else>
              <VDropdown
                class="tile is-12"
                :triggers="['hover', 'focus']"
                :offset="[0, 10]"
              >
                <template #popper>
                  <span class="is-size-7 has-text-link">クリックでお題を変更できます。</span>
                </template>
                <button
                  class="tile is-12 button is-fullwidth"
                  :class="[roomData.title ? 'is-info is-large' : 'is-info is-outlined is-small']"
                  @click="() => isTitleEdit = !isTitleEdit"
                >
                  <span class="icon"><i class="fas fa-cat"></i></span>
                  <h2>{{ roomData.title ? roomData.title : 'お題を設定する' }}</h2>
                </button>
              </VDropdown>
            </template>
          </div>
          <div class="tile is-parent buttons are-medium">
            <template v-if="!roomData.isVoting">
              <VDropdown
                class="tile is-12"
                :triggers="['hover', 'focus']"
                :offset="[0, 10]"
              >
                <template #popper>
                  <span class="is-size-7 has-text-link">投票を開始します。</span>
                </template>
                <button v-if="!roomData.isVoting"
                  class="button is-primary is-light is-fullwidth"
                  @click="startVoting"
                  :disabled="!roomData.selectItems.length">
                  <span class="icon is-small"><i class="fas fa-hourglass-start"></i></span>
                  <span>投票開始</span>
                </button>
              </VDropdown>
            </template>
            <template v-else>
              <VDropdown
                class="tile is-6"
                :triggers="['hover', 'focus']"
                :offset="[0, 10]"
              >
                <template #popper>
                  <span class="is-size-7 has-text-link">投票を締め切り、現在の投票結果を表示します。</span>
                </template>
                <button
                  class="button is-link is-light is-fullwidth"
                  @click="finishVoting"
                  :disabled="!roomData.selectItems.length">
                  <span class="icon is-small"><i class="fas fa-gavel"></i></span>
                  <span>投票締め切り</span>
                </button>
              </VDropdown>
              <VDropdown
                class="tile is-6"
                :triggers="['hover', 'focus']"
                :offset="[0, 10]"
              >
                <template #popper>
                  <span class="is-size-7 has-text-link">開始中の投票をキャンセルします。投票結果は表示されません。</span>
                </template>
                <button
                  class="button is-light is-light is-fullwidth"
                  @click="cancelVoting"
                  :disabled="!roomData.selectItems.length">
                  <span class="icon is-small"><i class="fas fa-hourglass-start"></i></span>
                  <span>キャンセル</span>
                </button>
              </VDropdown>
            </template>
          </div>
          <template v-if="roomData.selectItems && roomData.selectItems.length">
            <div class="tile is-parent columns is-justify-content-flex-start buttons are-medium">
              <div class="column is-2"
                v-for="selectItem in roomData.selectItems"
                :key="selectItem"
              >
                <button @click="selectHundole"
                  class="button is-rounded is-fullwidth"
                  :value="selectItem"
                  :class="[roomData.isVoting ? 'is-primary' : '', isSelected(selectItem) ? 'is-light' : 'is-black']"
                  :disabled="!roomData.isVoting">
                  {{ selectItem }}
                </button>
              </div>
            </div>
          </template>
          <div class="columns" v-if="selectInfo">
            <template v-if="roomData.isJanken">
              <div class="column">
                <article class="message is-medium is-info">
                  <div class="message-header">
                    <p>Winner : {{ selectInfo.max }}</p>
                  </div>
                </article>
              </div>
              <div class="column">
                <article class="message is-medium is-danger">
                  <div class="message-header">
                    <p>Loser : {{ selectInfo.min }}</p>
                  </div>
                </article>
              </div>
            </template>
            <template v-else>
              <div class="column">
                <article class="message is-medium is-primary">
                  <div class="message-header">
                    <p>Average : {{ selectInfo.average }}</p>
                  </div>
                </article>
              </div>
              <div class="column">
                <article class="message is-medium is-info">
                  <div class="message-header">
                    <p>Maximum : {{ selectInfo.max }}</p>
                  </div>
                </article>
              </div>
              <div class="column">
                <article class="message is-medium is-danger">
                  <div class="message-header">
                    <p>Minimum : {{ selectInfo.min }}</p>
                  </div>
                </article>
              </div>
            </template>
          </div>
          <div class="tile is-parent columns is-12 is-flex-direction-row is-flex-wrap-wrap">
            <template v-if="usersData">
              <div class="column is-2"
                v-for="(user) in usersData"
                :key="user.id"
              >
                <div class="notification is-12"
                  :class="userPanelClass(user)">
                  <VDropdown
                    :triggers="['hover', 'focus']"
                    :offset="[0, 10]"
                  >
                    <template #popper>
                      <span class="is-size-7 has-text-link"><strong>{{ user.name }}</strong>さんを退場させる</span>
                    </template>
                    <button class="delete" @click="exitRoom(user.id)"></button>
                  </VDropdown>
                  <p class="subtitle is-6" :class="userPanelClass(user)">
                    {{ user.name ? user.name : 'Under naming...' }}
                  </p>
                  <template v-if="roomData.isStart">
                    <template v-if="roomData.isVoting">
                      <template v-if="user.voting.id !== roomData.voting || !user.voting.selected">
                        <span class="button is-loading is-fullwidth" :class="userPanelClass(user)">voting</span>
                      </template>
                      <template v-else>
                        <span class="button is-fullwidth" :class="userPanelClass(user)">
                          <span class="icon"><i class="fas fa-pen-square"></i></span>
                        </span>
                      </template>
                    </template>
                    <template v-else-if="user.voting.id === roomData.voting">
                      <span class="title"
                        :class="userPanelClass(user)">
                        {{ user.voting.select }}
                      </span>
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import roomUtil from '../common/roomUtil'

export default {
  name: 'PokerRoom',
  data: function () {
    const url = window.location.origin
    const joinRoomId = window.location.pathname.match(/\/(.+)$/) ? window.location.pathname.match(/\/(.+)$/)[1] : ''
    return {
      url,
      roomId: '',
      userId: '',
      name: '',
      isOwner: false,
      joinRoomId,
      custom: false,
      customItems: [1, 2, 3],
      isShowNotification: true,
      isShowSettingModal: false,
      roomData: null,
      usersData: null,
      isTitleEdit: false,
      isPolling: false,
      isGhost: false,
      selectInfo: null,
      isActiveNavbarMenu: false,
    }
  },
  emits: ['rewrite'],
  methods: {
    setRoomId: async function () {
      const response = await roomUtil.createRoom()
      this.roomId = response.roomId
      this.userId = response.userId
      this.isOwner = true
      history.replaceState('','',`/${this.roomId}`);
    },
    setRoomData: function (roomDataString) {
      const roomData = roomDataString
      this.roomData = roomData
    },
    joinRoom: async function (isGhost) {
      const response = await roomUtil.joinRoom(this.joinRoomId, isGhost)
      if (response) {
        this.roomId = this.joinRoomId
        this.userId = response.userId
        if (isGhost) {
          this.isGhost = isGhost
          this.userId = 'ghost'
          this.setRoomData(response.roomData)
          this.polling()
        }
      } else {
        this.joinRoomId = 'roomが存在しません。'
      }
    },
    setName: async function () {
      const response = await roomUtil.setName(this.name)
    
      if (response) {
        this.setRoomData(response.roomData)
        this.polling()
      }
    },
    setOwner: function(value) {
      this.isOwner = value
    },
    setSelectItems: async function () {
      const selectItems = this.customItems.filter(item => item)
      const response = await roomUtil.setSelectItems(selectItems)
      if (response) {
        this.setRoomData(response.roomData)
        this.closeShowSettingModal()
      }
    },
    selectHundole: async function (e) {
      if (this.isGhost) return
      const response = await roomUtil.setSelected(e.target.value)
      if (response) {
        this.setRoomData(response.roomData)
      }
    },
    isSelected: function(value) {
      if (this.isGhost || (this.isOwner && this.roomData.isVoting) || !this.usersData) return false
      const userData = this.usersData.find(userData => userData.id === this.userId)
      if (!userData || !userData.voting || userData.voting.id !== this.roomData.voting) return false
      return userData.voting.select == value
    },
    copyToClipboard: function () {
      const urlLink = `${this.url}/${this.roomId}`
      navigator.clipboard.writeText(urlLink)
    },
    customAdd: function () {
      let addItem = 0
      if (this.customItems.length) {
        const lastOneBefore = Number(this.customItems[this.customItems.length - 2])
        const lastOne = Number(this.customItems[this.customItems.length - 1])
        addItem = lastOneBefore && lastOne ? (lastOne + lastOne - lastOneBefore) : (lastOne ? lastOne + 1 : '')
      }
      this.customItems = [...this.customItems, addItem]
    },
    customTake: function () {
      if (!this.customItems.length) return
      const tmp = [...this.customItems]
      this.customItems = tmp.slice(0, -1)
    },
    mode1220: function () {
      this.customItems = [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, '☕️']
    },
    modeTension: function () {
      this.customItems = ['Extra best', 'Best', 'Good', 'Usually', 'Bad', 'Worst']
      this.setTitle('気分')
    },
    modeSML: function () {
      this.customItems = ['S', 'M', 'L', 'XL']
    },
    modePlayingCards: function () {
      this.customItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '11: Jack', '12: Queen', '13: King', '?: Joker']
    },
    modeFibonacci: function () {
      const fib = (n) => {
        let a = 1
        let b = 1
        for (let i = 3; i <= n; i++) {
          const c = a + b
          a = b
          b = c
        }
        return b
      }
      const arr = ['0', '0.5']
      for (let i = 2; i < 13; i++) {
        arr.push(fib(i))
      }
      arr.push('？')
      arr.push('☕️')
      this.customItems = arr
    },
    modeOkrSelfDegree: function () {
      this.customItems = ['5: Goalが見えそう', '4: 快調に進行中', '3: 進捗あり', '2: 停滞中', '1: むしろ後退']
      this.setTitle('OKR自信度')
    },
    modeHighMediumLow: function () {
      this.customItems = ['3: 高', '2: 中', '1: 低']
      this.setTitle('優先度')
    },
    modeJanlen: function () {
      this.customItems = ['✊: ぐー', '✌️: ちょき', '🖐️: ぱー']
      this.setTitle('じゃんけん✊✌️🖐️')
    },
    hideNotification: function () {
      this.isShowNotification = false
    },
    openShowSettingModal: function () {
      this.isShowSettingModal = true
    },
    closeShowSettingModal: function () {
      this.isShowSettingModal = false
    },
    startVoting: async function () {
      await roomUtil.startVoting()
    },
    finishVoting: async function () {
      await roomUtil.finishVoting()
    },
    cancelVoting: async function () {
      await roomUtil.cancelVoting()
    },
    setTitle: async function (title) {
      const response = await roomUtil.setTitle(title || '')
      if (response) {
        this.setRoomData(response.roomData)
        this.isTitleEdit = false
      }
    },
    getNumber: (terget) => {
      const s = `${terget}`.match(/(\d+\.\d+|\d+)[^\d]?.*$/)
      return s ? Number(s[1]) : null
    },
    calSelect: function () {
      if (this.roomData.isVoting || !this.roomData.voting || !this.usersData || !this.usersData.length) {
        this.selectInfo = null
        return
      }
      const selectNumbers = []
      for (const user of Object.values(this.usersData)) {
        if (this.roomData.isJanken) {
          selectNumbers.push(user.voting.select)
          continue
        } 
        const n = this.getNumber(user.voting.select)
        if (n === null || !user.voting.selected || user.voting.id !== this.roomData.voting) {
          this.selectInfo = null
          continue
        }
        selectNumbers.push(n)
      }
      if (!selectNumbers.length) {
        this.selectInfo = null
        return
      }
      if (this.roomData.isJanken) {
        const gu = selectNumbers.filter(e => e.match(/✊/))
        const choki = selectNumbers.filter(e => e.match(/✌️/))
        const par = selectNumbers.filter(e => e.match(/🖐️/))
        if (gu.length && choki.length && !par.length) {
            this.selectInfo = {
            average: null,
            max: gu[0],
            min: choki[0]
          }
        } else if (!gu.length && choki.length && par.length) {
            this.selectInfo = {
            average: null,
            max: choki[0],
            min: par[0]
          }
        } else if (gu.length && !choki.length && par.length) {
            this.selectInfo = {
            average: null,
            max: par[0],
            min: gu[0]
          }
        }

      } else {
        this.selectInfo = {
          average: selectNumbers.reduce((acc, cur) => acc + cur) / selectNumbers.length,
          max: selectNumbers.reduce((acc, cur) => acc > cur ? acc : cur),
          min: selectNumbers.reduce((acc, cur) => acc < cur ? acc : cur)
        }
      }
    },
    exitRoom: function (userId) {
      roomUtil.exitRoom(userId)
    },
    polling: async function () {
      if (this.isPolling) return
      this.isPolling = true
      roomUtil.setSyncFunction(this.syncFunctionRoomData, this.syncFunctionUsersData)
    },
    syncFunctionRoomData: function(roomData) {
      if (roomData) {
        this.roomData = roomData
        this.calSelect()
      } else {
        window.open(this.url, '_top')
      }
    },
    syncFunctionUsersData: function(usersData) {
      if (this.isGhost || usersData.find(user => user.id === this.userId)) {
        this.usersData = usersData
        this.calSelect()
      } else {
        window.open(this.url, '_top')
      }
    },
    userPanelClass: function (user) {
      const classList = []
      if (this.selectInfo) {
        if (this.roomData.isJanken) {
          if (user.voting.select === this.selectInfo.max) classList.push('is-info')
          else if (user.voting.select === this.selectInfo.min) classList.push('is-danger')
          else classList.push('is-warning')
        } else {
          if (this.getNumber(user.voting.select) === this.selectInfo.max) classList.push('is-info')
          else if (this.getNumber(user.voting.select) === this.selectInfo.min) classList.push('is-danger')
          else classList.push('is-warning')
        }
      } else {
        classList.push('is-warning')
      }
      return classList
    },
    activeNavbarMenu: function() {
      this.isActiveNavbarMenu = !this.isActiveNavbarMenu
    }
  }
}
</script>
