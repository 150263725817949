<template>
  <PokerRoom />
</template>

<script>
import PokerRoom from './components/PokerRoom.vue'

export default {
  name: 'App',
  components: {
    PokerRoom
  }
}
</script>
