import { createApp } from 'vue'
import {
  VTooltip,
  VClosePopper,
  Dropdown,
  Tooltip,
  Menu
} from 'v-tooltip'
import App from './App.vue'
import 'v-tooltip/dist/v-tooltip.css'
const capp = createApp(App)

capp.directive('tooltip', VTooltip)
capp.directive('close-popper', VClosePopper)
capp.component('VDropdown', Dropdown)
capp.component('VTooltip', Tooltip)
capp.component('VMenu', Menu)

capp.mount('#app')
