import { getFirestore, addDoc, doc, onSnapshot, getDoc, getDocs, setDoc, collection, deleteDoc } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAGX3fRMhcBlOh7t-vUB8xUuh89Yq-nyDk',
  authDomain: 'team-poker.firebaseapp.com',
  projectId: 'team-poker',
  storageBucket: 'team-poker.appspot.com',
  messagingSenderId: '673535454966',
  appId: '1:673535454966:web:60a9217c05b80e3ba686d7',
  measurementId: 'G-SZ0TZ1PTTB'
}

const app = initializeApp(firebaseConfig)
getAnalytics(app)
const db = getFirestore(app)

const createVotingId = () => Math.random().toString(32).substring(2)
const roomCollection = collection(db, 'room')
const getDocByRoomId = roomId => doc(db, 'room', roomId)
const usersCollection = userDoc => collection(userDoc, 'users')
const checkTimeout = async (joinRoomId, roomData) => {
  const checkDate = new Date()
  const updatedAt = roomData.updatedAt.toDate()
  checkDate.setHours(checkDate.getHours() - 1)
  if (updatedAt > checkDate) return true
  const querySnapshot = await getDocs(usersCollection(getDocByRoomId(joinRoomId)))
  for (const user of querySnapshot.docs) {
    await deleteDoc(doc(db, 'users', user.id))
  }
  await deleteDoc(docRef)
  return false
}
let roomId
let userId
let docRef
let userDocRef
let roomData
let usersData

const createUser = async (userDoc) => {
  userDocRef = await addDoc(usersCollection(userDoc), 
    {
      voting: {
        id: '',
        selected: false,
        select: '',  
      },
      name: '',
      updatedAt: new Date()
    }
  )
  userId = userDocRef.id
}

export default {
  createRoom: async () => {
    roomData = {
      selectItems: [],
      voting: '',
      updatedAt: new Date()
    }
    try {
      docRef = await addDoc(roomCollection, roomData);
      roomId = docRef.id
      await createUser(docRef)
    } catch (e) {
      return false
    }
    return {roomId, userId, roomData}
  },
  joinRoom: async (joinRoomId, isGhost) => {
    try {
      docRef = getDocByRoomId(joinRoomId)
      const docData = await getDoc(docRef)
      if (docData.exists() && await checkTimeout(joinRoomId, docData.data())) {
        roomData = {...docData.data()}
        roomId = joinRoomId
        if (!isGhost) {
          await createUser(docRef)
        }
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData, userId}
  },
  setSyncFunction: async (funcRoomData, funcUsersData) => {
    usersData = []

    onSnapshot(docRef, (doc) => {
      roomData = {...doc.data()}
      funcRoomData(roomData)
    })
    onSnapshot(usersCollection(getDocByRoomId(roomId)), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          usersData.push({id: change.doc.id, ...change.doc.data()})
        }
        if (change.type === 'modified') {
          usersData = usersData.map(user => 
            (user.id !== change.doc.id)
              ? user
              : {id: change.doc.id, ...change.doc.data()}
          )
        }
        if (change.type === 'removed') {
          usersData = usersData.filter(user => user.id !== change.doc.id)
        }
      })
      funcUsersData(usersData)

      if (roomData.isVoting && roomData.isStart) {
        if (!usersData.find(user => user.voting.id !== roomData.voting || !user.voting.selected)) {
          roomData.isVoting = false
          roomData.updatedAt = new Date()
          setDoc(docRef, roomData)
        }
      }
    })
  },
  exitRoom: async (exitUserId) => {
    try {
      if (usersData.find(user => user.id  === userId)) {
        await deleteDoc(doc(getDocByRoomId(roomId), 'users', exitUserId))
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  setName: async (name) => {
    try {
      let userData
      if (!usersData) {
        const docData = await getDoc(userDocRef)
        if (docData.exists()) {
          userData = {...docData.data(), id: docData.id}
        }
      } else {
        userData = usersData.find(user => user.id  === userId)
      } 
      if (userData) {
        userData.name = name
        userData.updatedAt = new Date()
        await setDoc(userDocRef, userData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  setSelectItems: async (selectItems) => {
    const checkJanken = () => {
      if (selectItems.find(e =>  `${e}`.match(/(\d+\.\d+|\d+)[^\d]?.*$/))) return false
      return selectItems.every(e =>  `${e}`.match(/✊|✌️|🖐️/))
    }
    try {
      if (roomData) {
        roomData.selectItems = selectItems
        roomData.isJanken = checkJanken()
        roomData.voting = createVotingId()
        roomData.updatedAt = new Date()
        await setDoc(docRef, roomData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  setSelected: async (value) => {
    try {
      const userData = usersData.find(user => user.id  === userId)
      if (userData) {
        userData.voting = {
          id: roomData.voting,
          selected: true,
          select: value
        }
        userData.updatedAt = new Date()
        await setDoc(userDocRef, userData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  startVoting: async () => {
    try {
      if (roomData) {
        roomData.voting = createVotingId()
        roomData.isVoting = true
        roomData.isStart = true
        roomData.updatedAt = new Date()
        await setDoc(docRef, roomData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  finishVoting: async () => {
    try {
      if (roomData) {
        roomData.isVoting = false
        roomData.updatedAt = new Date()
        await setDoc(docRef, roomData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  cancelVoting: async () => {
    try {
      if (roomData) {
        roomData.isStart = false
        roomData.voting = ''
        roomData.isVoting = false
        roomData.updatedAt = new Date()
        await setDoc(docRef, roomData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  },
  setTitle: async (title) => {
    try {
      if (roomData) {
        roomData.title = title
        roomData.updatedAt = new Date()
        await setDoc(docRef, roomData)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
    return {roomData}
  }
}
